<template>
  <div>
    <!-- {{mainAsset}} -->
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Cookie from "js-cookie";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../../../components/Table/components/iconEditCheck.vue";
import iconDetailSubAsset from "../../../components/Table/components/iconDetailSubAsset.vue";
import iconDeleteCheck from "../../../components/Table/components/iconDeleteCheck.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AgGridVue,
    iconEditCheck,
    iconDeleteCheck,
    iconDetailSubAsset,
  },
  props: {
    searchText: {
      type: String,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        maxWidth: 110,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return "";
        },
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.name_financial_note"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.type_financial"),
        field: "type",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.manage_category_financial_note"),
        field: "seedetail",
        cellRendererFramework: "iconDetailSubAsset",
        // sortable: true,
        suppressSizeToFit: true,
      },

      {
        headerName: this.$t("depreciation.edit_note_acc"),
        field: "edit",
        cellRendererFramework: "iconEditCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.delete"),
        field: "delete",
        cellRendererFramework: "iconDeleteCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
    ];
  },
  computed: {
    ...mapGetters({
      noteAccounting: "noteAccounting",
    }),

    rowData() {
      return this.noteAccounting.map((noteAccounting, index) => {
        return {
          name: noteAccounting.name,
          type: noteAccounting.type
            ? this.$t("depreciation." + noteAccounting.type)
            : "-",
          edit: { id_user: noteAccounting.note_accounting_id },
          delete: noteAccounting,
          seedetail: noteAccounting.note_accounting_id,
        };
      });
    },
  },
  watch: {
    searchText: function searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
* >>> .ag-theme-alpine {
  text-align: center;
}
</style>
