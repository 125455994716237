<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Modal Add Financial Note -->
    <SuccessDialog :msg="successMessage" v-show="showSuccess"></SuccessDialog>
    <b-modal
      id="addFinancialNote"
      title="BootstrapVue"
      hide-header-close
      hide-footer
      hide-header
      centered
    >
      <b-form-group>
        <h3 style="text-align: center">
          {{ $t("depreciation.add_name_financial_note") }}
        </h3></b-form-group
      >
      <b-row>
        <form @submit.prevent="addNoteAcc">
          <b-form-group>
            <b-col cols="12 mt-2">
              <b-input-group>
                <p>
                  {{ $t("depreciation.name_financial_note") }}
                </p>
                <b-form-input
                  v-model="name"
                  class="w-full"
                  required
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-input-group>
                <p>
                  {{ $t("depreciation.type_financial") }}
                </p>
                <select v-model="type" class="form-control">
                  <option value="tangible">{{
                    $t("depreciation.tangible")
                  }}</option>
                  <option value="intangible">{{
                    $t("depreciation.intangible")
                  }}</option>
                  <option value="Non-depreciated assets">{{
                    $t("depreciation.Non-depreciated assets")
                  }}</option>
                </select>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <div class="row flex justify-content-center text-center">
                <button
                  type="button"
                  class="cancel-button"
                  data-dismiss="modal"
                  @click="$bvModal.hide('addFinancialNote')"
                >
                  {{ $t("btn.canc") }}
                </button>
                <button
                  type="submit"
                  class="col confirm-button"
                  data-dismiss="modal"
                >
                  {{ $t("btn.save") }}
                </button>
              </div>
            </b-col>
          </b-form-group>
        </form>
      </b-row>
    </b-modal>
    <!-- Modal Edit Financial Note -->
    <b-modal
      id="editFinancialNote"
      title="BootstrapVue"
      hide-header-close
      hide-footer
      hide-header
      centered
    >
      <b-form-group>
        <h3 style="text-align: center">
          {{ $t("depreciation.manage_financial_note") }}
        </h3></b-form-group
      >
      <b-row>
        <form @submit.prevent="updateNoteAcc">
          <b-form-group>
            <b-col cols="12 mt-2">
              <b-input-group>
                <p>
                  {{ $t("depreciation.name_financial_note") }}
                </p>
                <b-form-input
                  v-model="name"
                  class="w-full"
                  required
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-input-group>
                <p>
                  {{ $t("depreciation.type_financial") }}
                </p>
                <select v-model="type" class="form-control">
                  <option value="tangible">{{
                    $t("depreciation.tangible")
                  }}</option>
                  <option value="intangible">{{
                    $t("depreciation.intangible")
                  }}</option>
                  <option value="Non-depreciated assets">{{
                    $t("depreciation.Non-depreciated assets")
                  }}</option>
                </select>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <div class="row flex justify-content-center text-center">
                <button
                  type="button"
                  class="cancel-button"
                  data-dismiss="modal"
                  @click="$bvModal.hide('editFinancialNote')"
                >
                  {{ $t("btn.canc") }}
                </button>
                <button
                  type="submit"
                  class="col confirm-button"
                  data-dismiss="modal"
                >
                  {{ $t("btn.save") }}
                </button>
              </div>
            </b-col>
          </b-form-group>
        </form>
      </b-row>
    </b-modal>
    <!-- Modal Delete -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content container-fluid pt-4 pb-2"
          style="border-radius: 10px"
        >
          <div class="row">
            <img class="del-icon mb-3" src="../../assets/delete_bin.svg" />
            <p class="mb-3">
              {{ $t("depreciation.click_todel") }}
            </p>
          </div>
          <div class="row flex justify-content-center text-center">
            <button type="button" class="cancel-button" data-dismiss="modal">
              {{ $t("btn.canc") }}
            </button>
            <button
              type="button"
              class="confirm-button confirm-button-red"
              data-dismiss="modal"
              @click="confirmDelete()"
            >
              {{ $t("btn.conf") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              class="d-flex filter text-light p-2 m-0"
              style="border: none"
              type="button"
              @click="openModalAddFinancialNote"
            >
              + {{ $t("depreciation.add_name_financial_note") }}
            </button>
          </div>
          <div class="search-box d-flex" style="border-radius: 10px;">
            <img src="@/assets/search-white.svg" width="20px" />
            <input
              type="text"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
        </div>
        <ManageFinancialNoteTable ref="userTable" :searchText="searchText" />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import ManageFinancialNoteTable from "../../components/Table/Depreciation/ManageFinancialNoteTable.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
export default {
  name: "DepreciationAsset",
  components: {
    ManageFinancialNoteTable,
    Loading,
    SuccessDialog,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      searchText: "",
      name: "",
      type: "tangible",
      successMessage: "",
      showSuccess: false,
      update_note_accounting_id: "",
      del_note_accounting_id: "",
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
      noteAccounting: "noteAccounting",
    }),
  },
  methods: {
    async openModalAddFinancialNote() {
      this.name = "";
      this.type = "tangible";
      await this.$bvModal.show("addFinancialNote");
    },
    async addNoteAcc() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.name = this.name.trim();
      if (this.name) {
        let data = {
          name: this.name,
          type: this.type,
          id_company: id_company,
        };
        try {
          this.createNoteAcc = await this.$store.dispatch("addNoteAcc", data);
          this.successMessage = this.$t("dialog.add_success");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          if (e.response) {
            if (
              e.response.data.message == "This name already in use by another"
            ) {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("depreciation.err_note_acc"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.name = "";
        this.type = "tangible";
        this.$store.dispatch("getAllNoteAccounting", id_company);
        this.$bvModal.hide("addFinancialNote");
      }
      this.isLoading = false;
    },
    async setEditNoteAcc(note_accounting_id) {
      this.update_note_accounting_id = note_accounting_id;
      const note_accounting = this.noteAccounting.find(
        (noteAcc) =>
          noteAcc.note_accounting_id == this.update_note_accounting_id
      );
      this.name = note_accounting.name;
      this.type = note_accounting.type;
    },
    async updateNoteAcc() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.name = this.name.trim();
      if (this.name) {
        let data = {
          name: this.name,
          type: this.type,
          note_accounting_id: this.update_note_accounting_id,
        };
        try {
          await this.$store.dispatch("updateNoteAcc", data);
          this.successMessage = this.$t("dialog.save_edit");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          if (e.response) {
            if (
              e.response.data.message == "This name already in use by another"
            ) {
              this.isLoading = false;
              return Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("depreciation.err_note_acc"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.name = "";
        this.type = "tangible";
        this.$store.dispatch("getAllNoteAccounting", id_company);
        this.$bvModal.hide("editFinancialNote");
      }
      this.isLoading = false;
    },
    showDelete(noteAccounting) {
      this.del_note_accounting_id = noteAccounting.note_accounting_id;
    },
    async confirmDelete() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        await this.$store.dispatch(
          "deleteNoteAcc",
          this.del_note_accounting_id
        );
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
        this.$store.dispatch("getAllNoteAccounting", id_company);
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.$store.dispatch("getAllNoteAccounting", id_company);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  width: 100%;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}
.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}
.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

/*
  Animation
*/
</style>
